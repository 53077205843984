import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openDialog: false,
  costStructuresData: {},
  costStructureData: {},
  isLoading: false,
  error: false,
  messageCode: '',
  message: '',
  alert: '',
  alertMessage: '',
  refreshData: false,
  isEdit: false,
  clientDetails: {},
};

export const forecastSpendCategorySlice = createSlice({
  name: 'forecastSpendCategory',
  initialState: initialState,
  reducers: {
    getCostStructuresStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
      state.isLoading = true;
      state.costStructuresData = {};
    },
    getCostStructuresSuccess: (state, payload) => {
      state.isLoading = false;
      state.costStructuresData = payload.payload.costStructures;
      state.isLoading = false;
      state.refreshData = false;
    },
    getCostStructuresFailure: (state, { payload: { messageCode, message } }) => {
      state.isLoading = false;
      state.error = true;
      state.messageCode = messageCode;
      state.message = message;
      state.isLoading = false;
      state.refreshData = false;
    },
    updateClientDetails: (state, { payload: clientDetails }) => {
      state.clientDetails = clientDetails;
    },
    resetCostStructures: (state) => {
      state.costStructuresData = {};
    },
    openDialog: (state, payload) => {
      state.openDialog = true;
      state.costStructureData = payload?.payload?.costStructureData || {};
      state.isEdit = payload?.payload?.isEdit || false;
    },
    closeDialog: (state) => {
      state.openDialog = false;
      state.costStructureData = {};
      state.isEdit = false;
    },
    addforecastSpendCategoryStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
      state.alert = '';
      state.alertMessage = '';
    },
    addforecastSpendCategorySuccess: (state) => {
      state.isLoading = false;
      state.openDialog = false;
      state.alert = 'success';
      state.alertMessage = state.isEdit
        ? 'Successfully updated the spend category'
        : 'Successfully added the spend category';
      state.refreshData = true;
      state.costStructureData = {};
    },
    addforecastSpendCategoryFailure: (state) => {
      state.isLoading = false;
      state.error = true;
      state.alert = 'failure';
      state.alertMessage = state.isEdit ? 'Failed to update the spend category' : 'Failed to add the spend category';
    },
    closeAlert: (state) => {
      state.alert = '';
      state.alertMessage = '';
    },
    resetForecastSpendCategory: () => initialState,
  },
});

export const {
  getCostStructuresStart,
  getCostStructuresSuccess,
  getCostStructuresFailure,
  resetCostStructures,
  openDialog,
  closeDialog,
  updateClientDetails,
  addforecastSpendCategoryStart,
  addforecastSpendCategorySuccess,
  addforecastSpendCategoryFailure,
  closeAlert,
  resetForecastSpendCategory,
} = forecastSpendCategorySlice.actions;

export default forecastSpendCategorySlice.reducer;
