/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
  SET_ALLOW_PERCENTILE_VARIATION,
  SET_APPROVAL_FLOW_TYPE,
  SET_GROUP_NAME,
  SET_VALID_GROUP_SEQUENTIAL_FLOW_TYPE,
  SET_VALID_PARALLEL_FLOW_TYPE,
} from './constants';

const INIT_STATE = {
  module: 'cbre-pjm-approval-configuration-add-group.',
  approvalFlowTypeData: [],
  parallelFlowTypeFormData: [],
  groupSequentialFlowTypeFormData: [],
  initialGroupSequenceFlowTypeFormData: [],
  parallelFlowTypeRoles: [],
  approvalLevelList: [],
  selectedApprovalFlowType: '',
  groupName: '',
  rolesList: [],
  contactList: [],
  isValidFlowType: false,
  allowPercentileVariation: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_APPROVAL_FLOW_TYPE:
      return {
        ...state,
        selectedApprovalFlowType: action.payload,
      };
    case SET_ALLOW_PERCENTILE_VARIATION:
      return {
        ...state,
        allowPercentileVariation: action.payload,
      };
    case SET_GROUP_NAME:
      return {
        ...state,
        groupName: action.payload,
      };
    case SET_VALID_PARALLEL_FLOW_TYPE:
      return {
        ...state,
        isValidFlowType: action.payload.isValid,
        parallelFlowTypeFormData: action.payload.fields,
      };
    case SET_VALID_GROUP_SEQUENTIAL_FLOW_TYPE:
      return {
        ...state,
        isValidFlowType: action.payload.isValid,
        groupSequentialFlowTypeFormData: action.payload.fields,
      };
    default:
      return state;
  }
};
