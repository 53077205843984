export const GET_PDA_LIST_FILTER_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_FILTER_SUCCESS';
export const GET_PDA_LIST_FILTER_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_FILTER_FAILURE';
export const GET_PDA_LIST_FILTER_RESET = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_FILTER_RESET';
export const GET_PDA_DIVISION_FILTER_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_DIVISION_FILTER_SUCCESS';
export const GET_PDA_DIVISION_FILTER_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_DIVISION_FILTER_FAILURE';
export const GET_PDA_DIVISION_FILTER_RESET = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_DIVISION_FILTER_RESET';
export const GET_PDA_PROGRAM_FILTER_RESET = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROGRAM_FILTER_RESET';
export const GET_PDA_PROGRAM_FILTER_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROGRAM_FILTER_SUCCESS';
export const GET_PDA_PROGRAM_FILTER_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROGRAM_FILTER_FAILURE';

export const SET_CHECKED_PROJECT_LIST = 'CBRE-PJM-PDA-PROJECTS-LIST/SET_CHECKED_PROJECT_LIST';

export const OPEN_DELETE_PDA_DIALOG = 'CBRE-PJM-PDA-PROJECTS-LIST/OPEN_DELETE_PDA_DIALOG';
export const OPEN_DELETE_PDA_DIALOG_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/OPEN_DELETE_PDA_DIALOG_SUCCESS';
export const OPEN_DELETE_PDA_DIALOG_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/OPEN_DELETE_PDA_DIALOG_FAILURE';
export const DELETE_PDA_START = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_PDA_START';
export const DELETE_PDA_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_PDA_SUCCESS';
export const DELETE_PDA_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_PDA_FAILURE';
export const CLOSE_DELETE_PDA_TOAST = 'CBRE-PJM-PDA-PROJECTS-LIST/CLOSE_DELETE_PDA_TOAST';
export const CLOSE_DELETE_PDA_DIALOG = 'CBRE-PJM-PDA-PROJECTS-LIST/CLOSE_DELETE_PDA_DIALOG';

export const DELETE_CPP_START = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_CPP_START';
export const DELETE_CPP_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_CPP_SUCCESS';
export const DELETE_CPP_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/DELETE_CPP_FAILURE';

export const GET_PROJECT_COLUMNS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROJECT_COLUMNS';
export const GET_PROJECT_COLUMNS_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROJECT_COLUMNS_SUCCESS';
export const GET_PROJECT_COLUMNS_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_PROJECT_COLUMNS_FAILURE';
export const GET_MANAGE_COLUMNS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_MANAGE_COLUMNS';
export const GET_MANAGE_COLUMNS_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_MANAGE_COLUMNS_SUCCESS';
export const GET_MANAGE_COLUMNS_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_MANAGE_COLUMNS_FAILURE';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_SIMPLE_LOOKUPS_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_SIMPLE_LOOKUPS_SUCCESS';
export const GET_ALL_SIMPLE_LOOKUPS_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_SIMPLE_LOOKUPS_FAILURE';
export const GET_ALL_CONTACT_LOOKUPS_SUCCESS = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_CONTACT_LOOKUPS_SUCCESS';
export const GET_ALL_CONTACT_LOOKUPS_FAILURE = 'CBRE-PJM-PDA-PROJECTS-LIST/GET_ALL_CONTACT_LOOKUPS_FAILURE';
export const RESET_COLUMNS = 'CBRE-PJM-PDA-PROJECTS-LIST/RESET_COLUMNS';
export const LOADING_COLUMNS = 'CBRE-PJM-PDA-PROJECTS-LIST/LOADING_COLUMNS';
export const GET_PDA_PROJECT_MANAGER_FILTER_RESET = 'GET_PDA_PROJECT_MANAGER_FILTER_RESET';
export const GET_PDA_PROJECT_MANAGER_FILTER_SUCCESS = 'GET_PDA_PROJECT_MANAGER_FILTER_SUCCESS';
export const GET_PDA_PROJECT_MANAGER_FILTER_FAILURE = 'GET_PDA_PROJECT_MANAGER_FILTER_FAILURE';
